.experience-page {
  padding-left: 7.5vw;
  height: 100%;
  width: 100%;

  .arrowUp {
    margin-top: 4px;
    margin-right: 100px;
  }

  .arrowDown {
    margin-bottom: -5px;
    margin-right: 100px;
  }

  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '</h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }

  h1.page-title {
    margin-left: 100px;
    margin-top: 50px;
  }

  .fading-text {
    width: 60%;
    height: 40px;
    margin-left: 100px;
  }

  .timeline {
    margin-top: 31vh;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}

@media screen and (max-height: 800px) {
  .timeline {
    margin-top: 21rem !important;
  }
}
