.references-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  .arrowUp {
    margin-top: 49px;
    margin-right: 5px;
  }

  .arrowDown {
    margin-bottom: 43px;
    margin-right: 5px;
  }

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .login {
    padding-top: 3%;
    margin-left: 3%;
  }

  .reviews-container {
    top: 5vh;
    left: 1.5vw;
    padding: 2vh 0;
    width: 85%;
    position: relative;
    display: flex;
    align-items: center;
    color: beige;

    .review-box {
      position: relative;
      flex: 1 1 20%;
      height: 350px;
      border-radius: 10px;
      margin: 0 3.5vw;
      text-align: center;
      padding: 15px 0;
      border: 1px solid #ffd700;
      scale: 1;
      transition: scale 0.5s;

      &:hover {
        scale: 1.1;
      }

      .review-image {
        z-index: 2;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        margin-bottom: 15px;
        object-fit: cover;
      }

      .title {
        color: #ffd700;
        font-size: 1.7em;
        font-weight: 500;
      }

      .occupation {
        color: #cbe2b4;
        font-size: 1.2em;
        margin: auto;
      }

      .comment {
        font-size: 1.2em;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 5px;
        color: #fff;
        padding: 0 10px;
      }
    }

    .react-multiple-carousel__arrow--left {
      left: 0;
    }

    .react-multiple-carousel__arrow--right {
      right: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .references-page {
    position: absolute;
    top: 100px;
    height: auto;
    min-height: auto;
  }
}

@media screen and (max-width: 900px) {
  .reviews-container {
    justify-content: center !important;
  }
  .review-box {
    margin-top: 4%;
  }
}

@media screen and (max-width: 600px) {
  .review-box {
    min-width: 90% !important;
    max-width: none;
    height: auto !important;
    transition: none !important;

    &:hover {
      scale: none !important;
    }
  }

  .login {
    margin-top: 20px;
  }
}

@media screen and (max-height: 800px) and (min-width: 1200px) {
  .login {
    padding-top: 3% !important;
  }

  .reviews-container {
    padding: 1% 4% 0 4% !important;
  }

  .review-box {
    max-height: 280px !important;
    min-width: 220px !important;
    max-width: 220px !important;

    .review-image {
      width: 60px !important;
      height: 60px !important;
    }

    .title {
      font-size: 1.5em !important;
    }

    .occupation {
      font-size: 1.1em !important;
    }

    .comment {
      font-size: 1em !important;
    }
  }
}
