.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.page-nav {
  position: absolute;
  right: 3vw;
  &:hover {
    cursor: pointer;
    color: rgb(214, 202, 202);
  }
  animation: fadeIn 1s 2.8s backwards;
  transition: top 0.4s, bottom 0.4s, transform 2s;
}

.previous-page {
  top: 0vh;
  &:hover {
    top: -2vh;
  }
}

.next-page {
  bottom: 0vh;
  &:hover {
    bottom: -2vh;
  }
}

.top-tags {
  bottom: auto;
  top: 35px;
  margin-left: -20px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;
}

.top-tag-body {
  margin-left: 20px;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page,
.container.references-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '</h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  p {
    font-size: 17px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container {
    position: absolute;
    top: 100px;
    height: auto;
    min-height: auto;

    &.contact-page,
    &.about-page,
    &.portfolio-page,
    &.references-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    &.portfolio-page,
    &.references-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .image-box {
        height: 200px;
        max-width: calc(50% - 10px);
      }
    }
  }

  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 500px;
    overflow: visible;
  }
}
