.portfolio-page {
  padding-left: 90px;
  padding-right: 50px;
  width: calc(100% - 60px);
  position: initial;
  height: 100%;
  overflow: auto;

  .arrowUp {
    position: fixed;
    right: 7vw;
    margin-top: 49px;
    margin-right: 5px;
  }

  .arrowDown {
    position: fixed;
    right: 7vw;
    margin-bottom: 43px;
    margin-right: 5px;
  }

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .main-content {
    margin-top: 6vh;
    margin-left: 15px;
    width: 95%;
    // border: 1px solid red;
    display: flex;

    .filters-container {
      max-width: 25vw;

      .filter.active-category {
        color: #ff9900;
        font-weight: 700;
      }

      .filter {
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 1.7em;
        opacity: 0.6;
        line-height: 2.7vh;

        &:hover {
          cursor: pointer;
          color: #ff9e0d;
          font-weight: 700;
        }
      }

      .child-projects {
        margin-left: 1.5vw;
      }
    }

    .images-container {
      width: 67vw;
      margin-left: 50px;
      display: flex;
      // gap: 3%;
      gap: 2rem;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .image-box {
      position: relative;
      flex: 1 1 20%;
      min-height: 150px;
      max-height: 25vh;
      overflow: hidden;
      border-radius: 10px;
      max-width: calc(23%);

      .portfolio-image {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .content {
        position: absolute;
        width: 100%;
        z-index: 3;
        padding: 10px 0px 10px 10px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2) 0,
          rgba(0, 0, 0, 1)
        );
        bottom: -70px;
      }

      .title {
        width: 93%;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        color: #fff;
        font-size: calc(0.75vw + 0.8vh);
        font-weight: 500;
        line-height: 20px;
      }

      .description {
        font-size: calc(0.4vw + 0.6vh);
        margin-bottom: 6px;
        width: 90%;
        color: #fff;
        font-weight: 700;
      }

      .btn {
        margin-top: 2vh;
        margin-bottom: 0.6vh;
        padding: 0 8%;
        height: calc(1.5vw + 1.5vh);
        line-height: 34px;
        border: 2px solid #ffd700;
        border-radius: 4px;
        font-size: calc(0.7vw + 0.4vh);
        color: #fff;
        background: transparent;
        text-transform: uppercase;
        font-weight: 700;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
      }

      .btn:hover {
        transform: translateY(-3px);
        background: #ffd700;
      }

      &:after {
        content: '';
        background: linear-gradient(180deg, #ffd700, #000);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        opacity: 0;
      }

      &:hover:after {
        opacity: 0.85;
      }

      &:hover .content {
        bottom: 0;
        background: transparent;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-page {
    position: absolute;
    top: 100px;
    height: auto;
    min-height: auto;
    .main-content {
      .filters-container {
        max-width: 20vw;
        .filter {
          font-size: 1.4em;
        }
      }
      .images-container {
        width: 75vw;
        gap: 1.5rem;
      }
      .image-box {
        min-height: 150px;
        max-height: 20vh;
        .title {
          font-size: calc(0.6vw + 1vh);
        }
        .description {
          font-size: calc(0.3vw + 0.5vh);
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .portfolio-page {
    .main-content {
      flex-direction: column;
      .filters-container {
        max-width: 100%;
        text-align: center;
        .filter {
          font-size: 1.2em;
        }
      }
      .images-container {
        justify-content: center;
        width: 100%;
        margin-left: 0;
        gap: 3rem;
      }
      .image-box {
        flex-basis: 100%;
        max-width: 80%;
        .title {
          font-size: calc(0.8vw + 1vh) !important;
        }
        .description {
          font-size: calc(0.5vw + 0.5vh) !important;
        }
      }
    }
  }
  .arrowDown {
    z-index: 2;
  }
}
