.splash-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  z-index: 1000;

  // Adding a pseudo-element for the blurred background
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../assets/images/excelBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(1px); // Blurring the background
    z-index: -1; // This ensures the background stays behind the content
  }

  .fx-icon {
    width: auto; // To maintain the original aspect ratio of the image
    height: 2em; // Adjust this according to your design preferences. The image will scale based on height.
    align-self: center; // This ensures the icon is vertically centered
  }

  // Excel formula bar styling
  & > span:not(.fx-icon) {
    // This targets the TypeAnimation span but not the fx-icon
    padding: 0.3em 1em;
    border: 1px solid #d4d4d4;
    // background: linear-gradient(to bottom, #f9f9f9, #e9e9e9);
    background: white;
    border-radius: 4px;
    box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.6),
      0 2px 3px rgba(0, 0, 0, 0.1);
    width: 80%; // You can adjust this as needed
    text-align: left;
    overflow: hidden;
    white-space: wrap; // Ensures content stays on a single line
  }

  // Styling the typing text
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5rem;
  color: #333;

  // Animation to fade out the splash screen
  &.fade-out {
    animation: fadeOut 0.8s forwards;

    @keyframes fadeOut {
      to {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  // Media Query for screens up to 1200px
  @media screen and (max-width: 1200px) {
    & > span:not(.fx-icon) {
      width: 75%; // Reduced width for a bit of padding on medium-sized screens
    }

    .fx-icon {
      height: 1.8em; // Slightly reduced icon size
    }

    font-size: 1.2rem; // Reduce font size a bit
  }

  // Media Query for screens up to 600px (primarily mobile devices)
  @media screen and (max-width: 600px) {
    & > span:not(.fx-icon) {
      width: 70%; // Using more width for small screens
      font-size: 1.4em; // Bigger font size for readability
    }

    .fx-icon {
      height: 1.5em; // Further reduce the icon size
    }

    font-size: 1rem; // Set base font size for mobile
  }
}
