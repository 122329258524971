.input {
  position: relative;

  form {
    button {
      background: none;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 500;
      padding: 4px 8px;

      &:hover {
        cursor: pointer;
      }

      &:active {
        background: #ffc400;
      }
    }
  }

  .signout {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    color: #ff9100;
    font-weight: 500;
    font-size: 15px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
