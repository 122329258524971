.image {
  min-width: 70px;
  max-width: 140px;
  min-height: 100px;
  max-height: 140px;
  object-fit: cover;
  border-radius: 5px;
}

.image-even {
  position: absolute;
  bottom: 106%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.image-odd {
  margin-top: 5px;
}

.date {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  color: rgb(190, 190, 186);
  font-weight: 600;
}

.date-odd {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 215px;
  left: 0;
  right: 0;
}

.title {
  font-size: 15px;
  color: rgb(217, 224, 176);
  margin: auto;
  margin-top: -10px;
}

.title-odd {
  position: absolute;
  top: -54px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.description {
  font-size: calc(0.4vw + 0.65vh);
  color: white;
  padding: 0px 3px;
}

.description-odd {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 280px;
  left: 0;
  right: 0;
  text-align: center;
  vertical-align: bottom;
}

.next {
  color: #ffd700;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: sans-serif;
  text-decoration: none;
  text-align: center;
  padding: 10px 18px;
  border: none;
  animation: fadeIn 1s forwards;
  background-color: transparent;
  width: 200px;
  height: 60px;
  word-wrap: break-word;

  &:hover {
    cursor: pointer;
    color: #ffae00;
  }
}

@media screen and (max-width: 1200px) {
  .description {
    font-size: 1.4em;
    padding: 0px 2px;
  }
}

@media screen and (max-height: 850px) {
  .image {
    min-width: 70px;
    max-width: 120px;
    min-height: 90px;
    max-height: 120px;
    object-fit: cover;
    border-radius: 5px;
  }

  .date {
    font-size: 12px;
  }

  .title {
    font-size: 12px;
  }

  .description {
    font-size: 1.2em;
    padding: 0px 2px;
  }

  .date-odd {
    bottom: 198px;
  }

  .title-odd {
    top: -43px;
  }

  .description-odd {
    bottom: 248px;
  }
}
