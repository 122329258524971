.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    width: calc(75% - 70px); /* increasing width by 70px */
    height: 80%;
    background: white;
    position: relative;
    padding: 20px;
    padding-bottom: 40px;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(26, 25, 25, 0.3);
    border-radius: 10px;
    left: 35px; /* pushing content 35px to the left */
    z-index: 1001;

    .main-content {
      width: 90%;
      margin: auto;
      padding-left: 10%;
    }

    img {
      width: 80%; // Ensures images don't overflow the modal
      object-fit: cover;
      object-position: center;
      max-height: 300px;
      border-radius: 5px; // Slight rounding for a more polished look
      margin-bottom: 0rem; // Adds space beneath the image
      margin-top: 2rem;
    }

    h2 {
      font-size: 3.5rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }

    hr {
      margin-left: -0rem;
      margin-bottom: 3rem; // Your initial bottom margin
      width: 80%;
      align-self: start; // Align to the start (left) within the container, if it's a flex/grid container
    }

    p {
      font-size: 1.2rem;
      // line-height: 1.6;
    }

    .sections {
      display: grid;
      grid-template-columns: 1.2fr 9fr; // defining the column widths
      gap: 10px; // this replaces the margin-left on p.description
      width: 80%;

      p {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: right;
      }

      p.description {
        color: black;
        font-weight: 200;
        text-align: left;
      }

      h3 {
        font-size: 1.4rem;
        color: gray;
      }
    }

    &::-webkit-scrollbar {
      width: 12px; // Set the width of the scrollbar
      background-color: #f5f5f5; // Color of the track
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888888; // Color of the thumb (draggable area)
      border-radius: 0px; // Makes the thumb round

      &:hover {
        background-color: #555; // Color of thumb when hovered
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 6px; // Makes the track round
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); // Adds a little shadow inside the track
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 10px;
    background: none;
    border: none;
    font-size: 4.5rem;
    color: rgba(108, 116, 116, 0.932);

    &:hover {
      cursor: pointer;
      color: black;
    }
  }
}

@media screen and (max-width: 1200px) {
  .modal {
    .modal-content {
      width: 75%; /* Adjusting width */
      height: 70%; /* Adjusting height */
      left: 25px; /* Adjusting left push */
      padding: 15px; /* Adjusting padding */
    }

    .close-button {
      right: 10px;
      top: 8px;
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .modal {
    .modal-content {
      width: 90%; /* Increasing width */
      height: 60%; /* Reducing height for better view */
      left: 5px; /* Reducing left push */
      padding: 10px; /* Reducing padding */
    }

    .close-button {
      right: 8px;
      top: 6px;
      font-size: 1rem;
    }
  }
}
