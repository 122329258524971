.nav-bar {
  background: #181818;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;
    margin-bottom: -80px;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  .outer-nav {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;

    nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      height: 360px;
      margin: auto;
      width: 100%;
      transition: height 0.4s, transform 2s;

      &:hover {
        height: 440px;
      }

      a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
        width: 100%;

        i {
          transition: all 0.3s ease-out;
        }

        svg {
          transition: opacity 0.3s ease-out 0.18s; /* delay added here */
        }

        &:hover {
          color: #ffd700;

          svg {
            opacity: 0;
            transition-delay: 0s;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }

      a:active {
        animation: clickColorChange 1s forwards;
      }

      @keyframes clickColorChange {
        0% {
          color: #4d4d4e;
        }
        50% {
          color: #4d4d4e;
        }
        100% {
          color: #4d4d4e;
        }
      }

      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }

      a.experience-link {
        &:after {
          content: 'JOURNEY';
        }
      }

      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }

      a.references-link {
        &:after {
          content: 'COMMEND';
        }
      }

      a.portfolio-link {
        &:after {
          content: 'WORK';
        }
      }

      a.active {
        svg {
          color: #ffd700;
        }
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;
      }

      &:hover {
        .anchor-icon {
          color: #ffd700;
        }
      }
    }
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ul,
    nav {
      display: none;
    }

    nav {
      flex-direction: row !important;
      justify-content: space-between !important;
      text-align: center;
      height: 70px !important;
      top: 0;
      left: 0;
      background: #181818;
      margin: 0;
      z-index: 2;
      position: fixed;

      &:hover {
        height: 70px !important;
      }

      .close-icon {
        visibility: hidden;
      }

      &.mobile-show {
        display: block;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }
    }

    .hamburger-icon,
    .close-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      display: block;
    }
  }
}
