.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 11%;
  bottom: 0;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(10deg) !important;
  }

  .photo1,
  .photo2,
  .photo3 {
    box-sizing: border-box;
    border: 10px solid white;
    position: absolute;
    margin: auto;
    z-index: 1;
    width: 60%;
  }

  .photo1 {
    transform: rotateZ(10deg) !important;
    top: 20px;
    left: 200px;
  }

  .photo2 {
    transform: rotateZ(-5deg) !important;
    top: 40px;
    right: 270px;
  }

  .photo3 {
    transform: rotateZ(2deg) !important;
    top: 360px;
    left: 50px;
  }

  .tape-up1,
  .tape-up2,
  .tape-up3,
  .tape-down1,
  .tape-down2,
  .tape-down3 {
    position: absolute;
    margin: auto;
    width: 25%;
    z-index: 2;
  }

  .tape-up1 {
    transform: rotateZ(-75deg) !important;
    top: -30px;
    left: 190px;
  }

  .tape-down1 {
    transform: rotateZ(-85deg) !important;
    top: 265px;
    left: 350px;
  }

  .tape-up2 {
    transform: rotateZ(0deg) !important;
    top: 0px;
    left: 50px;
  }

  .tape-down2 {
    transform: rotateZ(-15deg) !important;
    top: 275px;
    left: -130px;
  }

  .tape-up3 {
    transform: rotateZ(-70deg) !important;
    bottom: 195px;
    left: 35px;
  }

  .tape-down3 {
    transform: rotateZ(-105deg) !important;
    bottom: -60px;
    left: 225px;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}

@media screen and (max-width: 1200px) {
  .logo-container {
    position: static !important;
    width: 80% !important;
    display: flex;
    margin-left: 5% !important;
    margin-top: 6vh !important;

    .photo1,
    .photo2,
    .photo3 {
      position: static;
      transform: none !important;
      width: 210px !important;
      height: 250px;
      object-position: center;
    }

    .tape-up1,
    .tape-up2,
    .tape-up3,
    .tape-down1,
    .tape-down2,
    .tape-down3 {
      // position: relative !important;
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 600px) {
  .logo-container {
    position: static !important;
    width: 80% !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto !important;
    margin-top: 3vh !important;
    margin-bottom: 6vh !important;

    .photo1,
    .photo2,
    .photo3 {
      position: static;
      transform: none !important;
      width: 270px !important;
      height: 300px;
      object-position: center !important;
    }

    .tape-up1,
    .tape-up2,
    .tape-up3,
    .tape-down1,
    .tape-down2,
    .tape-down3 {
      // position: relative !important;
      visibility: hidden;
    }
  }
}

@media screen and (max-height: 800px) {
  .photo1,
  .photo2,
  .photo3 {
    width: 50% !important;
  }

  .photo1 {
    top: 60px !important;
  }

  .photo2 {
    top: 60px !important;
  }

  .photo3 {
    top: 330px !important;
  }

  .tape-up1,
  .tape-up2,
  .tape-up3,
  .tape-down1,
  .tape-down2,
  .tape-down3 {
    width: 18% !important;
  }

  .tape-up1 {
    top: 25px !important;
    left: 200px !important;
  }

  .tape-down1 {
    top: 275px !important;
    left: 335px !important;
  }

  .tape-up2 {
    top: 35px !important;
    left: 70px !important;
  }

  .tape-down2 {
    top: 266px !important;
    left: -82px !important;
  }

  .tape-up3 {
    bottom: 242px !important;
    left: 37px !important;
  }

  .tape-down3 {
    bottom: 25px !important;
    left: 205px !important;
  }
}
